import React from 'react'
import Layout from '../components/layout'
import MainWrap from '../components/mainwrap'

const NotFoundPage = () => (
  <Layout>
      <MainWrap>
        <h1>Oops</h1>
        <p>Ingenting her :)</p>
        </MainWrap>
  </Layout>
)

export default NotFoundPage
